/* eslint-disable react/jsx-no-target-blank */
import "../Home/internal.css";
import "../Home/mainstyle.css";
import mail from "../assets/images/mail.svg";
import globe from "../assets/images/globe.svg";
import youtube from "../assets/images/youtube.svg";
import facebook from "../assets/images/footer-facebook.png";
import instagram from "../assets/images/footer-instagram.png";
import phone from "../assets/images/phone.svg";

import { useParams } from "react-router-dom";

import "./User.css";

const contacts = {
  syedhabeeburahman: {
    name: "Syed Habeeburahman",
    email: "habeeb@infinix.me",
    phone: "+971 55 483 5569",
    title: "Technical Director",
    firstName: "Syed Habeeburahman",
    lastName: ""
  },
  syedsajjathhussain: {
    name: "Syed Sajjath Hussain",
    email: "sajjath@infinix.me",
    phone: "+971 567 15 8879",
    title: "Managing Director",
    firstName: "Syed Sajjath",
    lastName: "Hussain"
  }

}

const User = () => {




  let { name } = useParams();

  const contact = contacts[name]

  const handleButtonClick = () => {
    // create a vcard file
    var vcard = "BEGIN:VCARD\nVERSION:3.0\nFN:" + contact.name + "\nTEL;TYPE=work,voice:" + contact.phone + "\nTITLE:" + contact.title + "\nEMAIL:" + contact.email + "\nN:" + contact.lastName + ";" + contact.firstName + ";;\nEND:VCARD";
    var blob = new Blob([vcard], { type: "text/vcard" });
    var url = URL.createObjectURL(blob);

    const newLink = document.createElement('a');
    newLink.download = contact.name + ".vcf";
    newLink.textContent = contact.name;
    newLink.href = url;

    newLink.click();
  };
  if (!contact) return null
  return (
    <div className="user-container">
      <a
        data-w-id="e2c41eac-496c-f159-1a57-a056f0741e18"
        href="/"
        aria-current="page"
        className="w-inline-block w--current"
        style={{ marginTop: "10%" }}
      >
        <div style={{ marginTop: "10%" }} className="w-embed">
          <svg
            width="169"
            height="40"
            viewBox="0 0 169 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M32.5194 25.0516H26.9682C26.7786 21.395 25.2854 18.4299 22.2751 16.3316C20.2083 14.8917 17.8854 14.3992 15.3872 14.5128C10.9974 14.7118 6.86845 18.3352 5.85398 22.9486C4.78262 27.8082 7.20977 32.3363 12.4954 35.1451C11.9835 36.5897 11.5426 38.1007 10.8837 39.5074C10.7509 39.7916 9.67958 39.9289 9.215 39.73C3.59749 37.2718 -0.142776 31.1569 0.00418042 24.8053C0.208023 16.3506 6.51291 9.58212 14.861 8.86691C23.1948 8.15169 30.6137 13.7076 32.2966 21.9776C32.4909 22.9391 32.4435 23.948 32.5194 25.0516Z"
              fill="white"
            />
            <path
              id="Vector_2"
              d="M39.7724 12.424H45.5701C45.371 17.6816 46.0299 22.887 44.6267 27.9598C42.7021 34.9035 35.8757 39.6116 28.3383 39.3464C21.2465 39.0953 14.7709 33.7241 13.2445 26.7899C13.0691 25.9894 12.96 25.1652 12.8842 24.3458C12.8273 23.7159 12.8747 23.0764 12.8747 22.3328C13.3393 22.3091 13.6806 22.2618 14.0172 22.276C15.3161 22.3186 16.8662 21.9681 17.8333 22.5602C18.5776 23.0196 18.5633 24.6774 18.9141 25.7905C20.379 30.418 23.8964 33.3405 28.3335 33.6389C32.8039 33.9373 37.004 31.6116 38.7342 27.4388C39.4785 25.6484 39.6349 23.5501 39.744 21.575C39.9051 18.5815 39.7866 15.5691 39.7866 12.4146L39.7724 12.424Z"
              fill="white"
            />
            <path
              id="Vector_3"
              d="M39.9526 6.40857V0.824219H45.409V6.40857H39.9526Z"
              fill="white"
            />

            <path
              d="M59.5073 32.373H60.8057V38.99H59.5073V32.373Z"
              fill="white"
            />
            <path
              d="M63.9407 32.373H65.0781L71.3688 37.2201V32.373H72.6672V39.0001H71.5298L65.2391 34.1429V38.99H63.9407V32.373Z"
              fill="white"
            />
            <path
              d="M75.7952 32.373H76.9325L83.2232 37.2201V32.373H84.5216V39.0001H83.3843L77.0936 34.1429V38.99H75.7952V32.373Z"
              fill="white"
            />
            <path
              d="M89.3506 32.373H95.0374C95.4602 32.373 95.893 32.4837 96.2553 32.7954C96.6076 33.0971 96.8492 33.5898 96.8492 34.163V37.2101C96.8492 37.7732 96.6076 38.266 96.2553 38.5777C95.893 38.8794 95.4602 38.99 95.0374 38.99H89.3506C88.938 38.99 88.4951 38.8794 88.1328 38.5777C87.7805 38.266 87.549 37.7732 87.549 37.2101V34.163C87.549 33.5898 87.7805 33.0971 88.1328 32.7954C88.4951 32.4837 88.938 32.373 89.3506 32.373ZM89.5117 33.4491C89.3204 33.4491 89.1393 33.5094 89.0386 33.5999C88.9279 33.6904 88.8474 33.801 88.8474 34.0927V37.2704C88.8474 37.562 88.9279 37.6827 89.0386 37.7732C89.1393 37.8637 89.3204 37.914 89.5117 37.914H94.8764C95.0676 37.914 95.2488 37.8637 95.3495 37.7732C95.4602 37.6827 95.5407 37.562 95.5407 37.2704V34.0927C95.5407 33.801 95.4602 33.6904 95.3495 33.5999C95.2488 33.5094 95.0676 33.4491 94.8764 33.4491H89.5117Z"
              fill="white"
            />
            <path
              d="M104.797 38.99H103.71L98.9695 32.373H100.57L104.254 37.5117L107.927 32.373H109.538L104.797 38.99Z"
              fill="white"
            />
            <path
              d="M114.288 32.373H115.375L120.115 38.99H118.505L117.408 37.4615H112.244L111.147 38.99H109.547L114.288 32.373ZM114.831 33.8513L113.019 36.3855H116.643L114.831 33.8513Z"
              fill="white"
            />
            <path
              d="M120.39 32.373H129.268V33.4491H125.473V38.99H124.175V33.4491H120.39V32.373Z"
              fill="white"
            />
            <path
              d="M131.893 32.373H133.191V38.99H131.893V32.373Z"
              fill="white"
            />
            <path
              d="M138.027 32.373H143.714C144.137 32.373 144.569 32.4837 144.932 32.7954C145.284 33.0971 145.526 33.5898 145.526 34.163V37.2101C145.526 37.7732 145.284 38.266 144.932 38.5777C144.569 38.8794 144.137 38.99 143.714 38.99H138.027C137.614 38.99 137.171 38.8794 136.809 38.5777C136.457 38.266 136.225 37.7732 136.225 37.2101V34.163C136.225 33.5898 136.457 33.0971 136.809 32.7954C137.171 32.4837 137.614 32.373 138.027 32.373ZM138.188 33.4491C137.997 33.4491 137.816 33.5094 137.715 33.5999C137.604 33.6904 137.524 33.801 137.524 34.0927V37.2704C137.524 37.562 137.604 37.6827 137.715 37.7732C137.816 37.8637 137.997 37.914 138.188 37.914H143.553C143.744 37.914 143.925 37.8637 144.026 37.7732C144.137 37.6827 144.217 37.562 144.217 37.2704V34.0927C144.217 33.801 144.137 33.6904 144.026 33.5999C143.925 33.5094 143.744 33.4491 143.553 33.4491H138.188Z"
              fill="white"
            />
            <path
              d="M148.554 32.373H149.691L155.982 37.2201V32.373H157.28V39.0001H156.143L149.852 34.1429V38.99H148.554V32.373Z"
              fill="white"
            />
            <path
              d="M162.029 32.373H167.001C167.414 32.373 167.857 32.4837 168.219 32.7954C168.551 33.0669 168.773 33.5195 168.803 34.0323H167.494C167.484 33.7809 167.414 33.6804 167.313 33.5999C167.202 33.5094 167.031 33.4491 166.84 33.4491H162.19C161.999 33.4491 161.818 33.5094 161.717 33.5999C161.606 33.6904 161.526 33.801 161.526 34.0927V34.4346C161.526 34.7262 161.606 34.8368 161.717 34.9273C161.818 35.0178 161.999 35.0782 162.19 35.0782H167.202C167.615 35.0782 168.058 35.1988 168.42 35.5005C168.773 35.8022 169.004 36.295 169.004 36.8682V37.2101C168.994 37.7732 168.773 38.266 168.41 38.5777C168.048 38.8794 167.615 39.0001 167.192 39.0001H162.029C161.606 39.0001 161.173 38.8794 160.811 38.5777C160.459 38.266 160.227 37.7732 160.227 37.2101V37.0089H161.526C161.526 37.1397 161.526 37.2704 161.526 37.2704C161.526 37.562 161.606 37.6827 161.717 37.7732C161.818 37.8637 161.999 37.914 162.19 37.914H167.031C167.223 37.914 167.404 37.8637 167.504 37.7732C167.615 37.6827 167.696 37.562 167.696 37.2704C167.696 37.2704 167.696 36.8883 167.696 36.6871C167.675 36.476 167.605 36.3855 167.514 36.305C167.404 36.2145 167.233 36.1542 167.041 36.1542H162.029C161.616 36.1542 161.173 36.0335 160.811 35.7318C160.469 35.4402 160.237 34.9675 160.227 34.4144V34.163C160.227 33.5898 160.459 33.0971 160.811 32.7954C161.173 32.4837 161.616 32.373 162.029 32.373Z"
              fill="white"
            />

            <path
              d="M59.0042 3.12834H63.3951V26H59.0042V3.12834Z"
              fill="white"
            />
            <path
              d="M74.0991 6.81283H82.6087C84.0043 6.81283 85.502 7.22995 86.7274 8.27273C87.5784 9.03743 88.2251 10.1497 88.5314 11.4358C88.6335 11.9572 88.6676 12.4786 88.7016 13.0348C88.8037 14.5642 88.7697 24.5053 88.7357 26H84.3107C84.3107 23.7754 84.3107 13.5909 84.3107 13C84.2766 11.6444 84.0384 11.3663 83.6639 11.0535C83.2895 10.7406 82.7109 10.5321 82.0641 10.5321H72.4653C71.7845 10.5321 71.2059 10.7406 70.8314 11.0535C70.4911 11.3663 70.2188 11.6444 70.2188 13C70.2188 13.2086 70.1507 26 70.1507 26H65.7597V6.84759H70.1507V8.16845C71.342 7.19519 72.7716 6.81283 74.0991 6.81283Z"
              fill="white"
            />
            <path
              d="M98.5252 0H104.856V3.71925H99.0698C98.1848 3.71925 97.8104 3.92781 97.47 4.24064C96.8573 4.83155 96.8232 5.00535 96.8232 6.81283H104.856V10.5321H96.8232V26H92.3983V10.5321H89.6071V6.84759H92.4323C92.4323 6.84759 92.4663 5.10963 92.6025 4.623C92.8748 3.30214 93.5215 2.18984 94.4065 1.45989C95.6319 0.417111 97.1296 0 98.5252 0Z"
              fill="white"
            />
            <path
              d="M105.7 1.1123H110.091V4.83155H105.7V1.1123ZM105.7 6.84759H110.091V26H105.7V6.84759Z"
              fill="white"
            />
            <path
              d="M120.697 6.81283H129.207C130.602 6.81283 132.1 7.22995 133.325 8.27273C134.176 9.03743 134.823 10.1497 135.13 11.4358C135.232 11.9572 135.266 12.4786 135.3 13.0348C135.402 14.5642 135.368 24.5053 135.334 26H130.909C130.909 23.7754 130.909 13.5909 130.909 13C130.875 11.6444 130.636 11.3663 130.262 11.0535C129.888 10.7406 129.309 10.5321 128.662 10.5321H119.063C118.383 10.5321 117.804 10.7406 117.43 11.0535C117.089 11.3663 116.817 11.6444 116.817 13C116.817 13.2086 116.749 26 116.749 26H112.358V6.84759H116.749V8.16845C117.94 7.19519 119.37 6.81283 120.697 6.81283Z"
              fill="white"
            />
            <path
              d="M137.431 1.1123H141.822V4.83155H137.431V1.1123ZM137.431 6.84759H141.822V26H137.431V6.84759Z"
              fill="white"
            />
            <path
              d="M142.42 6.84759H148.615L155.695 13.6257L162.775 6.84759H169.004L158.691 16.5107L168.834 26H162.605L155.695 19.361L148.785 26H142.556L152.7 16.5107L142.42 6.84759Z"
              fill="white"
            />
          </svg>
        </div>
      </a>
      <div
        style={{
          marginTop: "10%",
          width: "90%",
          height: "auto",
        }}
        className="spacing-bottom-04-px is-track-record bg--2 radius-24"
      >
        <div className="user-detail about-container is-track-record">
          <div className="user-text">{contact.name}</div>
          <div className="user-design space-top-10">{contact.title}</div>
          <div
            style={{ width: "90%" }}
            className="separator bg--1 space-top-10  opacity-02"
          ></div>

          <div className="social-details">
            <div className="single-social-detail">
              <div className="gray-background">
                <img src={phone} alt="" />
              </div>
              <a className="social-text" target="_blank" href={`tel:${contact.phone}`}>
                <div>{contact.phone}</div>
              </a>
            </div>
            <div className="single-social-detail">
              <div className="gray-background">
                <img src={mail} alt="" />
              </div>
              <a
                className="social-text"
                target="_blank"
                href={`mailto: ${contact.email}`}
              >
                <div>{contact.email}</div>
              </a>
            </div>
            <div className="single-social-detail">
              <div className="gray-background">
                <img src={globe} alt="" />
              </div>
              <a
                className="social-text"
                target="_blank"
                href="https://infinix.me/"
              >
                <div>www.infinix.me</div>
              </a>
            </div>
            <div className="single-social-detail">
              <div className="gray-background">
                <img src={youtube} alt="" />
              </div>
              <a
                className="social-text"
                href="https://www.youtube.com/@InfinixInnovations-hp1sv"
                target="_blank"
              >
                <div>infinixinnovation</div>
              </a>
            </div>
            <div className="single-social-detail">
              <div className="gray-background">
                <img src={facebook} alt="" style={{ width: 17, height: 30 }} />
              </div>
              <a
                className="social-text"
                target="_blank"
                href="https://www.facebook.com/profile.php?id=61555398414386&mibextid=9R9pXO"
              >
                <div>infinixinnovation</div>
              </a>
            </div>
            <div className="single-social-detail">
              <div className="gray-background">
                <img src={instagram} alt="" style={{ width: 30, height: 30 }} />
              </div>
              <a
                className="social-text"
                target="_blank"
                href="https://www.instagram.com/infinixinnovations?igsh=MXVqaHV2emVxdHRiZA%3D%3D&utm_source=qr"
              >
                <div>infinixinnovations</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div onClick={handleButtonClick} className="user-btn">
        Save Contact
      </div>
      <div className="user-btn">
        <a
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
          href="/catalogue.pdf"
          target="_blank"
        >
          {" "}
          Catalogue
        </a>
      </div>
      <div className="user-btn">
        <a
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
          href="/company-profile.pdf"
          target="_blank"
        >
          {" "}
          Profile
        </a>
      </div>
      <div style={{ height: 100 }}></div>
    </div>
  );
};
export default User;
